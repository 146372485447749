var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full overflow-hidden relative"},[(_vm.loading)?_c('div',{staticClass:"absolute bg-box-80 z-10 h-full w-full top-0 rounded-lg left-0"},[_c('loader',{attrs:{"loading":_vm.loading}})],1):_vm._e(),(_vm.mobile)?_c('div',{staticClass:"h-full overflow-auto no-scrollbar pb-8"},_vm._l((_vm.slSorted),function(el){return _c('pipeline-sl',{key:_vm.removeCharacters(el.id),attrs:{"data":el},on:{"nextPage":_vm.nextPage}})}),1):[_c('transition-group',{staticClass:"h-full overflow-y-auto",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.slSorted),function(i){return _c('div',{key:_vm.removeCharacters(i.id),staticClass:"box mb-2"},[_c('div',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"w-1 rounded-full h-6 mr-2",style:('background-color:' + _vm.legendColors[i.id].color + ';')}),_c('div',{staticClass:"text-2xl"},[_vm._v(_vm._s(i.name))]),_c('div',{staticClass:"h-8 w-8 rounded-lg flex flex-col justify-center items-center ml-auto cursor-pointer",class:{
              'bg-aux-15 text-aux': _vm.slSelectedItems.includes(i.id),
              'bg-filters-80 text-font-gray': !_vm.slSelectedItems.includes(i.id),
            },on:{"click":function($event){return _vm.toggleSlSelectedItems(i.id)}}},[(_vm.slSelectedItems.includes(i.id))?_c('i',{staticClass:"mdi mdi-eye-outline"}):_c('i',{staticClass:"mdi mdi-eye-off-outline"})])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 pr-4"},[_c('div',{staticClass:"flex items-center h-7 mb-3"},[_c('div',{staticClass:"text-tiny text-font-gray"},[_vm._v("Sales")]),_c('div',{staticClass:"ml-auto text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(i.total_sales,2))+"€ ")])]),_c('div',{staticClass:"flex items-center h-6 mb-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-4 text-font-dark text-sm"},[_vm._v("vs Obj")]),_c('div',{class:_vm.successOrDangerClass(i.objective_sale, 100)},[(i.objective_sale > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(i.objective_sale,2))),(
                      i.objective_sale != 'N/A' && i.objective_sale != 'TBD'
                    )?_c('span',[_vm._v("%")]):_vm._e()])]),_c('div',{staticClass:"flex items-center ml-auto"},[_c('div',{staticClass:"mr-4 text-font-dark text-sm"},[_vm._v("vs Avg(SL)")]),_c('div',[(i.avg_sale > 0 && i.avg_sale != 'TBD')?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(i.avg_sale,2))+" "),(i.avg_sale != 'TBD')?_c('span',[_vm._v("ptos.")]):_vm._e()])])]),_c('div',{staticClass:"rounded-full h-2 bg-progress overflow-hidden relative"},[(i.avg_sale != 'TBD')?_c('div',{staticClass:"rounded-full bg-success h-full absolute left-0",style:('width:' + i.avg_sale + '%;')}):_c('div',{staticClass:"rounded-full bg-success h-full absolute left-0",style:('width:100%;')}),(i.objective_sale != 'TBD')?_c('div',{staticClass:"rounded-full bg-blue z-10 h-full absolute left-0",style:('width:' + i.objective_sale + '%;')}):_c('div',{staticClass:"rounded-full bg-blue z-10 h-full absolute left-0",style:('width:100%;')})])]),_c('div',{staticClass:"w-3/10 px-4"},[_c('div',{staticClass:"flex items-center h-7 mb-3"},[_c('div',{staticClass:"text-tiny text-font-gray"},[_vm._v("Sales Estimated")]),_c('div',{staticClass:"ml-auto text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(i.estimated_sales,2))+"€ ")])]),_c('div',{staticClass:"flex justify-center items-center h-6 mb-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-4 text-font-dark text-sm"},[_vm._v("vs Obj")]),_c('div',[(i.objective_estimated_sales > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(i.objective_estimated_sales,2))),(
                      i.objective_estimated_sales != 'N/A' &&
                      i.objective_estimated_sales != 'TBD'
                    )?_c('span',[_vm._v("%")]):_vm._e()])])]),_c('div',{staticClass:"rounded-full h-2 bg-progress flex overflow-hidden"},[(
                  i.objective_estimated_sales != 'N/A' &&
                  i.objective_estimated_sales != 'TBD'
                )?_c('div',{staticClass:"rounded-full bg-warning h-full",style:('width:' + i.objective_estimated_sales + '%;')}):_c('div',{staticClass:"rounded-full bg-warning h-full",style:('width:100%;')})])]),_c('div',{staticClass:"w-1/5 pl-4"},[_c('div',{staticClass:"flex items-center h-7 mb-3"},[_c('div',{staticClass:"text-tiny text-font-gray"},[_vm._v("Effectiveness ratio")])]),_c('div',{staticClass:"flex justify-center items-center h-6 mb-4"},[_c('div',{staticClass:"text-xl"},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(i.effectiveness_ratio,2))+"% ")])]),_c('div',{staticClass:"rounded-full h-2 bg-progress flex overflow-hidden"},[_c('div',{staticClass:"rounded-full bg-danger h-full",style:('width:' + i.effectiveness_ratio + '%;')})])])])])}),0)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }